import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import {TrackJS} from 'trackjs';
import * as Sentry from "@sentry/react";

TrackJS.install({
    token: "e3cf028973ca480897c05a76e5470538",
    application: "client"
});

Sentry.init({
    dsn: "https://49d25eecbf1408f49327fde677cddfbf@o4508335801303040.ingest.de.sentry.io/4508335805104208",
    integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
        })
    ],
    environment: import.meta.env.VITE_ENVIRONMENT,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https?:\/\/.*\.xano\.io\/api/],
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
)
