import React from "react";
import {VariableSizeList} from 'react-window';

export interface VirtualListRef extends VariableSizeList {
    _outerRef: HTMLElement | null;
    _itemStyleCache: Record<string | number, object>;
}

export const safelyUnmountList = (listRef: React.MutableRefObject<VariableSizeList | null>) => {
    const unmountId = `unmount-${Date.now()}`;
    console.log(`[${unmountId}] Starting safe unmount of virtual list`);

    if (!listRef.current) return;

    try {
        console.log(`[${unmountId}] List ref exists, attempting cleanup`);

        try {
            console.log(`[${unmountId}] Attempting to reset list indices`);
            listRef.current.resetAfterIndex(0);
            console.log(`[${unmountId}] List indices reset successful`);
        } catch (resetError) {
            // Ignore reset errors, this is just a best-effort cleanup.
            console.log(`[${unmountId}] Error resetting list indices:`, resetError);
            console.debug('Reset error:', resetError);
        }
    } catch (e) {
        console.debug('List cleanup error:', e);
    } finally {
        setTimeout(() => {
            listRef.current = null;
            console.log(`[${unmountId}] List ref nulled in next tick`);
        }, 0);
    }
};
