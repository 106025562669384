import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Input} from "antd";
import {ModalComponent} from "../pages/candidatePool/ModalComponent.tsx";
import {IPosition, IPositions} from "../services/types.ts";
import {getSearchWhat} from "../services/api.ts";
import styled from "styled-components";
import {colors} from "../util/colors.ts";

interface IProfessionModal {
    isModalOpen: boolean;
    onClose: () => void;
    initialValue: string;
    handleSaveProf: (selectedOption: IPosition | null) => void;
}

export const ProfessionModal = ({isModalOpen, onClose, handleSaveProf, initialValue}: IProfessionModal) => {
    const isMounted = useRef(true);
    const componentId = useRef(`prof-modal-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`);
    const [isProcessingSelection, setIsProcessingSelection] = useState(false);

    const [whatSearch, setWhatSearch] = useState('');
    const [whatOptions, setWhatOptions] = useState<IPositions>([]);
    const [whatSelectedOption, setWhatSelectedOption] = useState<IPosition | null>(null);

    useEffect(() => {
        console.log(`[${componentId.current}] ProfessionModal mounted`);
        isMounted.current = true;
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            console.log(`[${componentId.current}] ProfessionModal unmounting`);
            isMounted.current = false;
        };
    }, []);

    const getWhatOptions = async () => {
        try {
            const data: IPositions = await getSearchWhat();
            console.log(`[${componentId.current}] Fetched ${data.length} profession options`);
            if (isMounted.current) {
                setWhatOptions(data);
            }
        } catch (error) {
            console.error('Error fetching what options:', error);
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            console.log(`[${componentId.current}] Modal opened, fetching options`);
            getWhatOptions();
        }
    }, [isModalOpen]);

    // Add debounce to prevent selection issues
    const toggleWhatOption = useCallback((selectedOption: IPosition) => {
        if (isMounted.current && !isProcessingSelection) {
            console.log(`[${componentId.current}] Starting option selection: ${selectedOption.label}`);
            setIsProcessingSelection(true);

            requestAnimationFrame(() => {
                if (isMounted.current) {
                    console.log(`[${componentId.current}] Setting selected option: ${selectedOption.label}`);
                    setWhatSelectedOption(selectedOption);
                    setIsProcessingSelection(false);
                }
            });
        }
    }, [isProcessingSelection, isMounted]);

    const filteredWhatOptions = useMemo(() => {
        return whatOptions.filter(option =>
            option.label.toLowerCase().includes(whatSearch.toLowerCase()) ||
            (option.matching_keywords && option.matching_keywords.toLowerCase().includes(whatSearch.toLowerCase()))
        );
    }, [whatOptions, whatSearch]);

    const onWhatSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (isMounted.current) {
            setWhatSearch(e.target.value);
        }
    }, []);

    const onModalClose = useCallback(() => {
        if (isMounted.current) {
            console.log(`[${componentId.current}] Modal closing sequence started`);
            // Clear selection state first
            setWhatSelectedOption(null);
            setWhatSearch('');
            // Then close the modal after a brief delay
            setTimeout(() => {
                if (isMounted.current) {
                    console.log(`[${componentId.current}] Executing modal close callback`);
                    onClose();
                }
            }, 10);
        }
    }, [onClose]);

    const handleSaveProfession = useCallback(() => {
        if (isMounted.current && !isProcessingSelection) {
            console.log(`[${componentId.current}] Save profession sequence started`);
            setIsProcessingSelection(true);

            // Capture the selected option in local variable
            const selectedOption = whatSelectedOption;
            console.log(`[${componentId.current}] Captured selection: ${selectedOption?.label || 'none'}`);

            // Use requestAnimationFrame for proper timing
            requestAnimationFrame(() => {
                if (isMounted.current) {
                    // Call the parent handler first with our captured value
                    console.log(`[${componentId.current}] Calling parent save handler`);
                    handleSaveProf(selectedOption);

                    // Wait until next frame to update local state
                    requestAnimationFrame(() => {
                        if (isMounted.current) {
                            setWhatSelectedOption(null);
                            setWhatSearch('');
                            console.log(`[${componentId.current}] Save sequence completed, resetting state`);
                            setIsProcessingSelection(false);
                        }
                    });
                } else {
                    console.log(`[${componentId.current}] Component unmounted during save sequence`);
                    // If component unmounted during this process, just clean up
                    setIsProcessingSelection(false);
                }
            });
        }
    }, [handleSaveProf, whatSelectedOption, isProcessingSelection, isMounted]);

    // Prevent unnecessary re-renders
    const memoizedOptions = useMemo(() => {
        return filteredWhatOptions.map((option) => (
            <WhatOption
                key={option.id}
                onClick={() => toggleWhatOption(option)}
                isSelectedOpt={whatSelectedOption?.id === option.id}
            >
                {option.label}
            </WhatOption>
        ));
    }, [filteredWhatOptions, whatSelectedOption, toggleWhatOption]);

    return <ModalComponent
        data-component-id={componentId.current}
        title="Was suchen Sie?"
        open={isModalOpen}
        onClose={onModalClose}
        onSave={handleSaveProfession}
        footerValue={
            <div style={{textAlign: 'left'}}>
                {whatSelectedOption?.label || initialValue || "Alle Positionen, kein Filter"}
            </div>
        }
    >
        <Input
            size="large"
            placeholder="Suche Berufsbezeichnung oder Tätigkeit..."
            value={whatSearch}
            onChange={onWhatSearch}
        />
        <WhatOptionsWrapper>
            {memoizedOptions}
        </WhatOptionsWrapper>
    </ModalComponent>
}

const WhatOptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 70vh;
    overflow-y: auto;
    margin-top: 16px;
    @media (max-width: 767px) {
        height: 40vh;
    }
`;

export const WhatOption = styled.div<{ isSelectedOpt: boolean }>`
    display: flex;
    padding: 16px;
    cursor: pointer;
    font-weight: ${props => props.isSelectedOpt ? 'bold' : 'normal'};
    background: ${props => props.isSelectedOpt ? colors.lightBlue : 'white'};

    &:hover {
        transition: all 0.3s ease;
        background-color: ${colors.lightBlue};
        border-radius: 4px;
    }
`;
