import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Input} from 'antd';
import {debounce} from 'lodash';
import {getSearchLocation} from "../services/api";
import {ILocation} from "../services/types";
import {ModalComponent} from "../pages/candidatePool/ModalComponent.tsx";
import {defaultWhereOptions} from "../util/utils.ts";
import styled from "styled-components";
import {WhatOption} from "./ProfessionModal.tsx";

interface IAddressModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (value: ILocation | null) => void;
    initialValue?: ILocation | null;
}

export const AddressModal: FC<IAddressModalProps> = ({open, onClose, onSave, initialValue}) => {
    const isMounted = useRef(true);
    const [isProcessingSelection, setIsProcessingSelection] = useState(false);

    const [whereSearch, setWhereSearch] = useState("");
    const [whereOptions, setWhereOptions] = useState<ILocation[]>(defaultWhereOptions);
    const [whereSelectedOption, setWhereSelectedOption] = useState<ILocation | null>(initialValue ?? null);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const debouncedSearch = useMemo(() =>
            debounce((value: string) => {
                if (!isMounted.current) return;

                if (value) {
                    getSearchLocation(value)
                        .then((data: ILocation[]) => {
                            if (isMounted.current) {
                                setWhereOptions(data);
                            }
                        })
                        .catch(error => console.error('Error fetching where options:', error));
                } else {
                    if (isMounted.current) {
                        setWhereOptions(defaultWhereOptions);
                    }
                }
            }, 300),
        []);

    useEffect(() => {
        if (open) {
            debouncedSearch(whereSearch);
        }

        return () => {
            debouncedSearch.cancel();
        };
    }, [whereSearch, debouncedSearch, open]);

    const handleSaveWhere = useCallback(() => {
        if (isMounted.current && !isProcessingSelection) {
            setIsProcessingSelection(true);

            // Capture the current selected option to prevent race conditions
            const selectedOption = whereSelectedOption;

            // Update local state first
            setWhereOptions(defaultWhereOptions);
            setWhereSearch("");

            // Use setTimeout to ensure DOM operations have completed
            requestAnimationFrame(() => {
                if (isMounted.current) {
                    // Pass the captured value, not the potentially changed state value
                    onSave(selectedOption);
                    setIsProcessingSelection(false);
                }
            });
        }
    }, [onSave, whereSelectedOption, isProcessingSelection]);

    const onModalClose = useCallback(() => {
        if (isMounted.current) {
            // Reset state first
            setWhereOptions(defaultWhereOptions);
            setWhereSearch("");

            // Then close modal after a brief delay
            setTimeout(() => {
                if (isMounted.current) {
                    onClose();
                }
            }, 10);
        }
    }, [onClose]);

    const onWhereSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (isMounted.current) {
            setWhereSearch(e.target.value);
        }
    }, []);

    const handleSelectWhere = useCallback((option: ILocation) => {
        if (isMounted.current && !isProcessingSelection) {
            setIsProcessingSelection(true);

            // Use setTimeout to debounce and prevent rapid re-renders
            setTimeout(() => {
                if (isMounted.current) {
                    if (option.label_client === "Alle Regionen und Postleitzahlen") {
                        setWhereSelectedOption({
                            ...option,
                            radius: undefined,
                            region: undefined,
                        });
                    } else if (option.label_client === "Homeoffice") {
                        setWhereSelectedOption({
                            ...option,
                            radius: 0,
                            region: 2,
                        });
                    } else {
                        setWhereSelectedOption(option);
                    }

                    setIsProcessingSelection(false);
                }
            }, 50);
        }
    }, [isProcessingSelection]);

    // Memoize the option list to prevent unnecessary re-renders
    const memoizedOptions = useMemo(() => {
        return whereOptions.map((option) => (
            <WhatOption
                key={option.id}
                onClick={() => handleSelectWhere(option)}
                isSelectedOpt={whereSelectedOption?.id === option.id}
            >
                {option.label_client}
            </WhatOption>
        ));
    }, [whereOptions, whereSelectedOption, handleSelectWhere]);

    return (
        <ModalComponent
            title="Wo suchen Sie?"
            open={open}
            onClose={onModalClose}
            onSave={handleSaveWhere}
            footerValue={
                <div style={{textAlign: 'left'}}>
                    {whereSelectedOption?.label_client || "Alle Standorte, kein Filter"}
                </div>
            }
        >
            <WhereWrapper>
                <Input
                    size="large"
                    style={{marginBottom: 8}}
                    placeholder="Suche um Postleitzahl (empfohlen) oder in Region"
                    value={whereSearch}
                    onChange={onWhereSearch}
                />
                {memoizedOptions}
            </WhereWrapper>
        </ModalComponent>
    );
};

const WhereWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 70vh;
    overflow-y: auto;
    margin-top: 16px;
    @media (max-width: 767px) {
        height: 40vh;
    }
`;

